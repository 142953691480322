/** @jsx jsx */
import { getImage } from 'gatsby-plugin-image'
import { arrayOf, oneOf, string } from 'prop-types'
import { Container, Heading, jsx } from 'theme-ui'

import ButtonList from '~/components/ButtonList'
import IntroTransition from '~/components/IntroTransition'
import Section from '~/components/Section'
import renderLink from '~/content/renderLink'
import renderRichText from '~/content/renderRichText'
import ContentLeftLayout from '~/layout/ContentLeftLayout'
import {
  ContentfulImagePropType,
  ContentfulImageType,
  ContentfulRichTextPropType,
  ContentfulRichTextType,
  LinkPropType,
  LinkType,
} from '~/types'

const DISPLAY_NAME = 'CopyAndCtaSection'

const ALIGNMENT_MAP = {
  Left: 'left',
  Center: 'center',
}

const ALIGNMENT_CONTAINER_VARIANT_MAP = {
  Left: 'container',
  Center: 'containerSmall',
}

const ALIGNMENT_BUTTON_JUSTIFY_MAP = {
  Left: 'flex-start',
  Center: 'center',
}

const PROP_TYPES = {
  backgroundImage: ContentfulImagePropType,
  body: ContentfulRichTextPropType.isRequired,
  elementId: string,
  heading: string.isRequired,
  links: arrayOf(LinkPropType),
  textAlign: oneOf(['Left', 'Center']),
  textColorScheme: oneOf(['Light', 'Dark']),
}

interface PropTypes {
  backgroundImage?: ContentfulImageType,
  body: ContentfulRichTextType,
  elementId?: string,
  heading: string,
  links?: LinkType[],
  textAlign: string,
  textColorScheme: 'Light' | 'Dark',
}

const Component = ({
  backgroundImage,
  body,
  elementId,
  heading,
  textAlign = 'Left',
  textColorScheme = 'Light',
  links,
}: PropTypes) => {
  const content = (
    <IntroTransition>
      <div sx={{ textAlign: ALIGNMENT_MAP[textAlign] }}>
        <Heading
          sx={{
            variant: 'text.heading1',
            marginBottom: 1,
          }}
        >
          {heading}
        </Heading>
        {renderRichText(body, { style: 'prospect' })}
        {links && (
          <ButtonList
            sx={{
              justifyContent: [null, ALIGNMENT_BUTTON_JUSTIFY_MAP[textAlign]],
              marginTop: 2,
            }}
          >
            {links?.map(({ id, ...link }) => (
              <li key={id}>
                {renderLink(link, { scheme: textColorScheme })}
              </li>
            ))}
          </ButtonList>
        )}
      </div>
    </IntroTransition>
  )
  const leftTextAlign = textAlign === 'Left'

  return (
    <Section
      backgroundImage={getImage(backgroundImage?.localFile)}
      id={elementId}
      textColorScheme={textColorScheme}
    >
      <Container variant={ALIGNMENT_CONTAINER_VARIANT_MAP[textAlign]}>
        {leftTextAlign ? (
          <ContentLeftLayout>
            {content}
          </ContentLeftLayout>
        ) : content}
      </Container>
    </Section>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
